<nb-card #dialog status={{status}}>
    <nb-card-header>
        <h6>{{message}}</h6>
    </nb-card-header>
    <nb-card-body *ngIf="data">
        <div *ngFor="let item of data | keyvalue">
            <ng-container *ngIf="isBasicType(item.value)">
                <strong>{{item.key}}: </strong> {{item.value}}
            </ng-container>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-6">
                <div class="text-center">
                    <button nbButton (click)="confirm()" status="{{status}}">Zatwierdź</button>
                </div>
            </div>
            <div class="col-6">
                <div class="text-center">
                    <button nbButton (click)="cancel()">Wyjdź</button>
                </div>
            </div>
        </div>
    </nb-card-footer>
</nb-card>