import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { SimpleDialogManagerService } from '../simple-dialogs/simple-dialog-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AlertManagerService {
  duration: number = 3000;
  status: string;

  constructor(private toastrService: NbToastrService, private dialogManager: SimpleDialogManagerService) { }
  

  

  showSuccessAlert({title = "Sukces!", message = "Pomyślnie zapisano zmiany.", duration = this.duration, status = "success"}: AlertNamedParams) 
  {
    this.toastrService.show(message, title, {status: status, duration: duration});
  }

  showFailAlert({title = "Uwaga!", message = "Coś poszło nie tak.", duration = this.duration, status = "danger"}: AlertNamedParams)
  {
    this.toastrService.show(message, title, {status: status, duration: duration});
  }

  showFailAlertDialog({title="Coś poszło nie tak", message="Operacja zakończona niepowodzeniem", status="warning"}: FailDialogParams) 
  {
    this.dialogManager.showWarning(title, message, status);
  }

}

interface AlertNamedParams {
  title?: string,
  message?: string,
  duration?: number,
  status?: any
}

interface FailDialogParams {
  title?: string,
  message?: string,
  status?: any
}