<nb-card #dialog status={{status}}>
    <nb-card-header>
        <h6>{{title}}</h6>
    </nb-card-header>
    <nb-card-body>
        <p>{{message}}</p>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col">
                <div class="text-center">
                    <button nbButton (click)="ok()">OK</button>
                </div>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
