import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Subject, SyncDefinitionListEntry } from '../../models/subject';


@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  api_url = environment.apiUrl;
  casched_subject : Observable<Subject | Partial<Subject>>;
 
  constructor( private http: HttpClient ) { }

  clearCache() {
    this.casched_subject = null;
  }

  getSubjectList(params: any) {
    return this.http.get(this.api_url + '/subjects', {params: params})
  }

  getSubject(uuid: string) {
    if(!this.casched_subject)
    {
      this.casched_subject = this.http.get(this.api_url + '/subjects/' + uuid).pipe(
        map((response: any) => response["data"]),
        shareReplay()
      );
    }
    return this.casched_subject;
  }

  patchSubject(uuid: string, subject: any): Observable<Partial<Subject> | Subject> {
    return this.http.patch(this.api_url + '/subjects/' + uuid + '/', subject).pipe(
      map((response: any) => response["data"])
    );
  }

  postSubject(subject: any): Observable<Partial<Subject> | Subject> {
    return this.http.post(this.api_url + '/subjects/', subject).pipe(
      map((response: any) => response["data"])
    );
  }

  deleteSubject(subject: any): Observable<any> {
    return this.http.delete(this.api_url + '/subjects/' + subject.uuid);
  }

  getOperatedList(): Observable<any> {
    return this.http.get(this.api_url+"/subjects/operated_list");
  }

  getExportResultFile(ext:string): Observable<Blob> {
    return this.http.get(this.api_url+"/subjects/export_operated_list/?ext="+ext,{responseType: 'blob'});
  }

  getBailiffsList(): Observable<any> {
    return this.http.get(this.api_url+"/subjects/bailffs_list");
  }

  getWhiteIpsList(uuid: string): Observable<any> {
    return this.http.get(this.api_url + `/subjects/white_ips_list?sub_uuid=${uuid}`);
  }

  addIpToWhiteList(uuid: string, ip: string): Observable<any> {
    return this.http.post(this.api_url + `/subjects/white_ips_list/`, {sub_uuid: uuid, ip: ip});
  }

  deleteIpToWhiteList(id: number): Observable<any> {
    return this.http.delete(this.api_url + `/subjects/white_ips_list/?id=${id}`);
  }

  public getSubjectByTempCode(params: any): Observable<any> {
    return this.http.post(this.api_url+"/subjects/get_subject_by_temp_code/", params)
  }

  addOperatedSubjects(subjects: any[]) : Observable<any> {
    return this.http.post(this.api_url+"/subjects/add_operated/", subjects);
  }

  removeFromOperatedList(list: any[]): Observable<any> {
    return this.http.post(this.api_url+"/subjects/operated_list/", list);
  }

  getSyncDefinitionList(): Observable<SyncDefinitionListEntry[]> {
    return this.http.get<SyncDefinitionListEntry[]>(`${this.api_url}/sync-definition/`);
  }

  createSyncDefinitionListEntry(entry: SyncDefinitionListEntry): Observable<SyncDefinitionListEntry> {
    return this.http.post<SyncDefinitionListEntry>(`${this.api_url}/sync-definition/`, entry);
  }

  updateSyncDefinitionListEntry(uuid: string, entry: Partial<SyncDefinitionListEntry>) : Observable<SyncDefinitionListEntry> {
    return this.http.put<SyncDefinitionListEntry>(`${this.api_url}/sync-definition/${uuid}/`, entry);
  }

  deleteSyncDefinitionListEntry(uuid: string): Observable<any> {
    return this.http.delete(`${this.api_url}/sync-definition/${uuid}/`);
  }

  public getTempRelationCode(nip_regon: string) : Observable<any> {
    return this.http.post(`${this.api_url}/temp-relation-codes/`, {identity: nip_regon});
  }

  public getLastActivityPeriods(params: any) :Observable<any> {
    return this.http.get(`${this.api_url}/import/plugins`, {params: params});
  }
  public getActiveSubjectPlugin(subject_uuid: any) :Observable<any> {
    return this.http.get(`${this.api_url}/import/${subject_uuid}/pluginsActivity`);
  }

  public getActivityPeriods(params: any) : Observable<any> {
    return this.http.get(`${this.api_url}/import/periods/history`, {params: params});
  }

  public getSubjectByNipRegon(lookup: string) : Observable<any> {
    return this.http.post(`${this.api_url}/subjects/get_by_nip_regon/`, {lookup: lookup});
  }

}
