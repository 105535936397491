import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-footer', 
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Created by <b>Smart Business Software sp. z o.o.</b> 2020</span> <span><b>ver: </b>{{ version }}</span> 
  `,
})
export class FooterComponent {
  version = environment.version
}
