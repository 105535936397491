import { Component, ViewChild, ElementRef } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" 
        tag="menu-sidebar" 
        responsive 
        start
        (stateChange)="checkSidebar()"
        >
        <ng-content select="nb-menu">
        </ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <div class="blur"
          [hidden]="!display_blur"
          (click) = "toggleSidebar()">
        </div>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  display_blur :boolean=false;
  public constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService
  ){}
  checkSidebar(){
    this.sidebarService.getSidebarState('menu-sidebar')
    .subscribe((state)=>{
      if(state==='expanded'){
        this.display_blur=true;
      }
      else{
        this.display_blur=false
      }
    })
  }
  // @HostListener('click')
  // clickInside($event) {
  //   $event.stopPropagation();
  // }


  toggleSidebar() {
    
      this.sidebarService.toggle(true, 'menu-sidebar');
      this.layoutService.changeLayoutSize();
      
    }


}
