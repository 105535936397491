import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { tableConfigColumn, tableConfigGroup } from '../table-config';
import { AlertManagerService } from '../../../../modules/common-features/services/simple-alerts/alert-manager.service';

const searchMenuTrigger = trigger('searchMenu', [
  state(
    'collapsed',
    style({
      overflow: 'hidden',
      visibility: 'hidden',
      height: 0,
    }),
  ),
  state(
    'expanded',
    style({
      overflow: 'hidden',
      visibility: 'visible',
    }),
  ),
  transition('collapsed => expanded', animate('100ms ease-in')),
  transition('expanded => collapsed', animate('100ms ease-out')),
]);
@Component({
  selector: 'bms-searching-filters',
  templateUrl: './searching-filters.component.html',
  styleUrls: ['./searching-filters.component.scss'],
  animations:[searchMenuTrigger]
})
export class SearchingFiltersComponent implements OnInit {
  
  @Input() tableConfig : Array<tableConfigColumn | tableConfigGroup>;
  @Output() searchEvent = new EventEmitter<{[Key :string]: Object}>();
  expanded:boolean=false;
  filters:{[Key :string]: string}={};
  waitForSearch = null;
  constructor(protected alertManager: AlertManagerService,) { }
 
  ngOnInit(): void {}

  onTagRemove(config:tableConfigColumn | tableConfigGroup): void {
    if(config.type=='group'){
      config["fields"].forEach(element => {
        delete this.filters[element["column"]]
      });
    }else{
      delete this.filters[config['column']];
    }
    clearTimeout(this.waitForSearch);
    this.waitForSearch=setTimeout(()=>this.search(),3000);
  }

  expand(){
    this.expanded = !this.expanded;
    if(!this.expanded){
      this.search();
    }
  }

  get state():string{return this.expanded ? 'expanded' : 'collapsed'}

  getFilterConfig(filter:any){
    var filterConfig: any ;
    this.tableConfig.forEach((value)=>{
      if(value['type']=='group'){
        var group_title=value["title"]
        value['fields'].forEach(element => {
          if(element['column']==filter.key){
            element["group_title"]=group_title
            filterConfig = element
          }
        });
      }else{
        if(value['column']==filter.key){
          filterConfig= value
        }
      }
    })
    return filterConfig
  }

  filterToText(config:any){
    var text ='';
    if(config.type=='group'){
      text = config["title"]+" - ";
      config["fields"].forEach(element => {
        if(element["filterType"]=='contains'){
          if(this.filters[element["column"]]===null){
              delete this.filters[element["column"]]
            }else{
              text+=this.filters[element["column"]]?'Zawiera ': 'Nie zawiera ';
            }
        }else{
          if(element["column"] in this.filters){
            if(this.filters[element["column"]]!=''){
              if( this.filters[element["column"]]!==null){
                text+=element["title"]+": "+this.formatValueToString(this.filters[element["column"]])+" ";
              }
            }else{
              delete this.filters[element["column"]];
            }
           
          }
        }
      });
    }else{
      if(this.filters[config["column"]]!=''){
        text =config["title"]+" - "+this.formatValueToString(this.filters[config["column"]])
      }else{
        delete this.filters[config["column"]];
      }
    }
    return text
  }
  formatValueToString(value){
    if(typeof value == 'boolean'){
      return value?'Tak':'Nie'
    }
    if(value instanceof Date){
      return value.toLocaleDateString('en-CA')
    }
    return value;
  }

  filterConfig(list:Array<tableConfigColumn | tableConfigGroup>){
    return list.filter((config)=>
      {
       if(config.type=='group'){
        if(config["fields"].map(item =>item["column"] in this.filters).some((item)=>item))
          return config
       }else{
        if(config["column"] in this.filters){
          return config
        }
       }
      }
    )
  } 

  isDisabled(config){
    if("disabledIfColumnNull" in config ){
      if(!(config["disabledIfColumnNull"] in this.filters)){
        delete this.filters[config.column]
        return true
      }
    }
    return false;
  }

  isAnyFilters(list:Array<tableConfigColumn | tableConfigGroup>){return this.filterConfig(list).length}
  filterChanged(column){
    if(this.filters[column]===null||this.filters[column]===''){
      delete this.filters[column];
    }
  }
  search(){
    clearTimeout(this.waitForSearch);
    this.searchEvent.emit({"filters":this.filters});
    this.expanded=false;
  }
  onKeydown(){
    clearTimeout(this.waitForSearch);
  }
}