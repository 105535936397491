import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(private dialogRef: NbDialogRef<any>) { }

  static checkNextPasswordChangeDate(date: string) {
    
    let now = new Date();
    let pw_change_date = new Date(date);
    
    if(now > pw_change_date)
    {
      return true;
    }
    else return false;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
