import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PermissionGuard } from './guards/permission.guard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { IsAdminGuard } from './guards/is-admin.guard';
import { AppLoginComponent } from './auth/app-login/app-login.component';
import { NbAuthComponent } from '@nebular/auth';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';


export const routes: Routes = [
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'admin',
    canLoad: [IsAdminGuard],
    canActivate: [IsAdminGuard],
        loadChildren: () => import('./modules/admin/admin.module')
          .then(m => m.AdminModule),
  },
  {
    path: 'customer',
    canActivate: [PermissionGuard],
    loadChildren: () => import('./modules/customer/customer.module')
      .then(m => m.CustomerModule),
    data: {
      preload: true
    }
   
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: 'login',
        component: AppLoginComponent,
        // <---
      },
      {
        path: 'password_reset',
        component: ResetPasswordComponent,
      },
      {
        path: 'request-password',
        component: RequestPasswordComponent
      },
      {
        path: '', redirectTo: 'login', pathMatch: 'full'
      }
    ],
  },
   
  { path: '', redirectTo: 'redirect', pathMatch: 'full' },
  { path: '**', redirectTo: 'redirect' },
      
];

const config: ExtraOptions = {
  useHash: false,
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
