<div class="header">
    <div class="buttons">
        <button 
            [status]="expanded?'basic':'info'"
            nbButton 
            ghost
            (click)="expand()"
        >
            Wyszukaj <nb-icon icon="search-outline"></nb-icon>
        </button> 
    </div>
    <ng-container *ngIf="isAnyFilters(tableConfig); else emptymessage">    
        <nb-tag-list >
                <nb-tag 
                    *ngFor="let config of filterConfig(tableConfig)"
                    [text]="filterToText(config)" 
                    [removable]="true" 
                    size="small" 
                    (remove)="onTagRemove(config)"
                    status="primary">
                </nb-tag>
            
        </nb-tag-list>
    </ng-container>
    <ng-template #emptymessage>
        <p class="no-data">Brak wybranych filtrów.</p>
    </ng-template>
</div>
<div [@searchMenu]="{ value: state }" class="filterTab" (keydown)="onKeydown()">
    <form  class="searchMenu" #form="ngForm" (keyup.enter)="form.valid ? search(): null">

        <div class="searchMenuGroup">
            <ng-container *ngFor="let config of tableConfig">
                <ng-container *ngIf="config.type!='group'">
                    <ng-container *ngTemplateOutlet="optionsFilterTemplate; context{config:config}"></ng-container>
                </ng-container>
            </ng-container>
        </div>
        
        <ng-container *ngFor="let config of tableConfig">
            <ng-container *ngIf="config.type=='group'" >
                <div class="searchMenuGroupHeader">{{config.title}}</div>
                <div class="searchMenuGroup">
                    <ng-container  *ngFor="let field of config.fields">
                        <ng-container *ngTemplateOutlet="optionsFilterTemplate; context{config:field}"></ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #optionsFilterTemplate let-config="config">
            <ng-container [ngSwitch]="config.filterType">
                <ng-container *ngSwitchCase="'float'"><ng-container *ngTemplateOutlet="floatFilterTemplate; context{config:config}"></ng-container></ng-container>
                <ng-container *ngSwitchCase="'text'"><ng-container *ngTemplateOutlet="textFilterTemplate; context{config:config}"></ng-container></ng-container>
                <ng-container *ngSwitchCase="'multiOptions'"><ng-container *ngTemplateOutlet="nbMultipleSelectFilterTemplate; context{config:config}"></ng-container></ng-container> 
                <ng-container *ngSwitchCase="'options'"><ng-container *ngTemplateOutlet="nbSelectFilterTemplate; context{config:config}"></ng-container></ng-container> 
                <ng-container *ngSwitchCase="'contains'"><ng-container *ngTemplateOutlet="containsTemplate; context{config:config}"></ng-container></ng-container> 
                <ng-container *ngSwitchCase="'date'"><ng-container *ngTemplateOutlet="datePickerFilterTemplate; context{config:config}"></ng-container></ng-container> 
            </ng-container>
        </ng-template>
        
        <ng-template #textFilterTemplate let-config="config">
            <div class="searchItem">
                <label>{{config.title}}: </label>
                <input
                    [name] = "config.column"
                    fullWidth 
                    type="text" 
                    nbInput
                    maxlength="150"
                    [(ngModel)]='filters[config.column]'
                    [disabled] = "isDisabled(config)"
                    (change)="filterChanged(config.column)"
                >
            </div>
        </ng-template>

        <ng-template #floatFilterTemplate let-config="config">
            <div  class="searchItem">
                <label>{{config.title}}: </label>
                <input
                    [name] = "config.column"
                    fullWidth 
                    type="text"
                    placeholder="0.0"
                    pattern="^-?\d+(\.\d+)?$"
                    nbInput
                    maxlength="13"
                    [(ngModel)]='filters[config.column]'
                    [disabled] = "isDisabled(config)"
                    (change)="filterChanged(config.column)"
                >
            </div>
        </ng-template>

        <ng-template #containsTemplate let-config="config">
            <div  class="searchItem">
                <nb-select fullWidth placeholder="" [(selected)]="filters[config.column]" style="margin-top:1.75rem">
                    <nb-option>----</nb-option>
                    <nb-option [value]="true">Zawiera</nb-option>
                    <nb-option [value]="false">Nie zawiera</nb-option>
                  </nb-select>
            </div>
        </ng-template>

        <ng-template #nbSelectFilterTemplate let-config="config">
            <div  class="searchItem">
                <label>{{config.title}}: </label>
                <nb-select 
                    fullWidth 
                    placeholder="Brak" 
                    [(selected)]="filters[config.column]"
                    [disabled] = "isDisabled(config)"
                    (change)="filterChanged(config.column)"
                >
                    <nb-option ></nb-option>
                    <nb-option *ngFor="let option of config.options" [value]="option">{{option}}</nb-option>
                </nb-select>
            </div>
        </ng-template>

        <ng-template #nbMultipleSelectFilterTemplate let-config="config">
            <div  class="searchItem">
                <label>{{config.title}}: </label>
                <nb-select 
                    multiple 
                    fullWidth 
                    placeholder="Brak" 
                    [(selected)]="filters[config.column]"
                    [disabled] = "isDisabled(config)"
                    (change)="filterChanged(config.column)"
                >
                    <nb-option >Brak</nb-option>
                    <nb-option *ngFor="let option of config.options" [value]="option">{{option}}</nb-option>
                </nb-select>
            </div>
        </ng-template>

        <ng-template #datePickerFilterTemplate let-config="config">
            <div class="searchItem">
                <label>{{config.title}}: </label>
                <input
                    [name] ="config.column"
                    fullWidth 
                    nbInput
                    placeholder="YYYY-MM-DD"
                    [(ngModel)]='filters[config.column]'
                    [disabled] = "isDisabled(config)"
                    [nbDatepicker]="picker"
                    (change)="filterChanged(config.column)"
                >
                <nb-datepicker 
                    format="yyyy-MM-dd" 
                    #picker
                ></nb-datepicker>
            </div>
        </ng-template>

    </form>
    <div class="filterTabButtons">
        <button 
            [disabled]="!form.valid"
            nbButton
            status="warning"
            ghost
            (click)="search()"
        >
            Wyszukaj<nb-icon icon="search-outline"></nb-icon>
        </button>
    </div>
</div>
