import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { sortObj } from '../table-config';

@Component({
  selector: 'bms-searching-table-header-item',
  templateUrl: './searching-table-header-item.component.html',
  styleUrls: ['./searching-table-header-item.component.scss']
})
export class SearchingTableHeaderItemComponent implements OnInit {

  @Input() sort: boolean=false;
  @Input() field:string|null = null;
  @Input() sortObj:sortObj = {
                              sortField:null,
                              sortOrder:null
                              }
  @Output() sortObjChange = new EventEmitter<sortObj>();
  constructor() { }

  ngOnInit(): void {
  }
  sortBy(column){
    if(this.sortObj.sortField==this.field){
      if(this.sortObj.sortOrder==0){
        this.sortObj.sortOrder = this.sortObj.sortField = null;
      }else{
        this.sortObj.sortOrder = 0;
      }
    }else{
      this.sortObj.sortField = column;
      this.sortObj.sortOrder = 1;
    }
    this.sortObjChange.emit(this.sortObj)
  }

}
