import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

export const JWT_WHITE_LIST = [
  environment.apiUrl+'/password_reset/',
  environment.apiUrl+'/password_reset/confirm/'
]


@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    constructor ( private authService: NbAuthService ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      
    const token = JSON.parse(localStorage.getItem('auth_app_token'));
    if(JWT_WHITE_LIST.includes(req.url))
    {
      return next.handle(req);
    }

    if (token) {
        const token_data = JSON.parse(token.value)
        const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token_data.access_token),
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}