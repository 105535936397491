import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() message: string;
  @Input() data: any;
  @Input() status: string;
  confirmed = false;

  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit(): void {
  }

  isBasicType(data: any): boolean {
    return (typeof data === 'string' || typeof data === 'number' || typeof data ==='boolean');
  }

  cancel() {
    this.confirmed = false;
    this.dialogRef.close(this.confirmed);
  }

  confirm() {
    this.confirmed = true;
    this.dialogRef.close(this.confirmed);
  }

  /* USAGE HINT 

  confirmDeleteUser(user: User) {
    this.dialogService.open(ConfirmDialogComponent, 
      {
        context: 
          { 
            message: "Czy na pewno chcesz usunać użytkownika "+ user.username + "?",
            data: user,
            status: "danger"
          }
      })
      .onClose.subscribe(confirmed => {
        if(confirmed) this.deleteUser(user);
      });
    }
  */
  
}
