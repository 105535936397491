import { 
  Component, 
  OnInit, 
  EventEmitter, 
  Output, Input, 
  SimpleChanges, 
  OnChanges
} from '@angular/core';
import {tableConfigColumn, tableConfigGroup } from './table-config';

@Component({
  selector: 'bms-searching-table',
  templateUrl: './searching-table.component.html',
  styleUrls: ['./searching-table.component.scss']
})
export class SearchingTableComponent implements OnInit, OnChanges {

  @Output() refreshEvent = new EventEmitter<Object>();
  @Output() onRowClick = new EventEmitter<Object>();

  @Input() tableConfig!: Array<tableConfigColumn | tableConfigGroup>;
  @Input() items: any[];

  @Input() totalRecords: number = 0;
  @Input() pageSize : number = 25;
  @Input() actualPageNumber:number=0;
  
  @Input() rowsPerPageOptions : number[] = [10,25,50,100];
  @Input() showPaginator:boolean=true;

  @Input() bodyTemplate: any;
  @Input() emptymessageTemplate:any;

  pages: number[] =[];

  tableColumns: Array<tableConfigColumn>;
  filters={}
  constructor() { }
  ngOnInit(): void {
  }
  ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange.items) {
      if(simpleChange.items.currentValue?.length) this.updatePages();
    }
  }
  reloadData(value: Object){
    this.filters=value["filters"]?value["filters"]:this.filters;
    this.pageSize=value["pageSize"]?value["pageSize"]:this.pageSize;
    var searchData= {
      "page": value["page"]?value["page"]:1,
      "filters" : this.filters,
      "page_size" : this.pageSize
    }
    this.refreshEvent.emit(searchData);
  }
  rowClicked(value: Object){this.onRowClick.emit(value)}

  lastPage(){
    let last_page =Math.ceil(this.totalRecords/this.pageSize)
    return last_page==0?1:last_page;
  }
  updatePages():void{
    let pages:number[]=[this.actualPageNumber]
    while(pages.length<5){
      if(pages[pages.length-1]<this.lastPage()){
        pages.push(pages[pages.length-1]+1)
      }
      if(pages[0]>1){
        pages.push(pages[0]-1)
      }
      pages.sort((a, b) => a - b)
      if(pages[0]==1 && pages[pages.length-1]==this.lastPage()){
        break
      }
    }
    this.pages=pages
  }
  tableScroled(element){
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
      });
  }
}
