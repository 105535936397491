<h1 id="title" class="title">Zapomniane hasło</h1>
<p class="sub-title">Wpisz swój adres e-mail, a wyślemy link do zresetowania hasła</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Niestety!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Sukcec!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Wpisz swój adres e-mail:</label>
    <input nbInput
           [(ngModel)]="user.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="Adres E-mail"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Adres e-mail jest wymagany!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Adres e-mail powinien być poprawny!
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !requestPassForm.valid"
          [class.btn-pulse]="submitted">
    Resetuj hasło
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Wróć do logowania</a></p>
</section>