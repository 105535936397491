import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthOAuth2JWTToken, NbAuthService, NbAuthToken } from '@nebular/auth';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Group } from '../../@core/data/models/group';
import { License } from '../../@core/data/models/license';
import { SubjectType } from '../../@core/data/models/subject_type';
import { User } from '../../@core/data/models/user';
import { CaseService } from '../../@core/data/services/case/case.service';
import { SubjectService } from '../../@core/data/services/subject/subject.service';
import { UserService } from '../../@core/data/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  constructor(private authService: NbAuthService, 
              private caseService: CaseService, 
              private userService: UserService, 
              private subjectService: SubjectService,
              private router: Router) {};

  getUserUUID(): Observable<any> {
    return this.getFromPayload("user_uuid").pipe(
      map((user_uuid) => user_uuid)
    )
  };

  getUser(): Observable<Partial<User>> {
    return this.getFromPayload("user").pipe(
      map((user) => user)
    )
  };

  getNextPwChange(): Observable<string>{
    return this.getFromPayload("next_pw_change").pipe(
      map((next_pw_change) => next_pw_change)
    ) 
  };
   
  //TODO: CLEANER CLASS do sprzątanie wszystkich cache podczas wylogowywania
  logout(params?: any) {
    this.clearCache();
    this.authService.logout("email").subscribe();
    this.router.navigate(["auth/login"], {queryParams: params});
  };

  clearCache() {
    this.caseService.clearCache();
    this.userService.clearCache();
    this.subjectService.clearCache();
    localStorage.removeItem('impersonated');
  };

  getSubject() {
    return this.getFromPayload("subject").pipe(
      map((subject) => subject)
    ) 
  };

  getGroups(): Observable<Group[]> {
    return this.getFromPayload("groups").pipe(
      map((groups) => groups)
    )   
  };

  getPerms(): Observable<string[]> {
    return this.getFromPayload("permissions").pipe(
      map((perms) => perms)
    )   
  };

  getCurrentLicense(): Observable<Partial<License>> {
    return this.getFromPayload("subject").pipe(
      map((subject: any) => subject.current_license)
    )
  };

  private getFromPayload(key: string): Observable<any> {
      return this.authService.getToken().pipe(
        map((token: NbAuthOAuth2JWTToken) => {
          try {
            return token.getAccessTokenPayload()[key];
          }
          catch {
            this.logout();
          }
        })
      )
  };
}
