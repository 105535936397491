import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() status: string;

  constructor(private dialogRef: NbDialogRef<any>) { }

  ngOnInit(): void {
  }

  ok() {
    this.dialogRef.close();
  }

/* USAGE HINT 
showWarning() {
  this.dialogService.open(WarningDialogComponent, 
    {
      context: 
        { 
          title: "Coś poszło nie tak",
          message: error,
          status: "danger"
        }
    })
  }
*/

}
