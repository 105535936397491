import { Injectable } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CurrentUserService } from '../../../../auth/services/current-user.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SimpleDialogManagerService {

  constructor(private dialogService: NbDialogService, private currentUserService: CurrentUserService) { }

  showWarning(title: string, message: string, status: string) {
    this.dialogService.open(WarningDialogComponent,
      {
        context: 
        { 
          title: title,
          message: message,
          status: status
        }    
      });
  }

  showConfirmDialog(data?: any, message?: string, status?:string): NbDialogRef<any> {
    return this.dialogService.open(ConfirmDialogComponent, 
      {
        context: 
          { 
            message: message,
            data: data,
            status: status
          }
      })
  }

  showPasswordChangeDialog() {
    let next_pw_change = null;
    this.currentUserService.getNextPwChange()
      .subscribe((date:string) => 
      {
        next_pw_change = date;
        if (next_pw_change != null && ChangePasswordDialogComponent.checkNextPasswordChangeDate(next_pw_change) == true )
        {
          this.dialogService.open(ChangePasswordDialogComponent, {closeOnBackdropClick: this.getImpersonated()});
        }
      })
  }


  private getImpersonated() :boolean {
    let impersonated_str = localStorage.getItem('impersonated');

    if (impersonated_str === 'true') return true;
    else return false;
  }
}
