<div class="container">
    <div class="row">
        <div class="col">
            <div class="text-center">
                <h1>Page Not Found 404</h1>
                <p>Nieprawidłowy adres url</p>
            </div>
        </div>
    </div>
</div>
