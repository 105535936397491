export interface tableConfigColumn{
    column:string,
    title:string,
    showOnMobile?:boolean,
    filterType:string,
    type?:string,
    field?:string,
    show?:boolean,
    width?:string,
    options?: Object[],
    disabledIfColumnNull?:string,
    sort?:boolean
}
export interface tableConfigGroup{
    title:string,
    type:string,
    fields:tableConfigColumn[]
}
export interface sortObj{
    sortField:string|null,
    sortOrder:number|null
}


