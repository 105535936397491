import { Subject, Relation } from './subject';

export interface Case {
    id?: number;
    uuid: string;
    caseno: string;
    subjects: Partial<Relation[]>;
    external_subjects?: Partial<Subject[]>;
    debtor_full_names?: string;
    enforcement_request_address?: string;
    act_signature?: string;
    execution_initial_date?: string;
    application_debt_balance?: string;
    chosen_by_creditor?:boolean;
    debtors? : any[];
    summons_debtor? : any[];
    actual_status_str?: string;
    case_category_str?: string;
    saldo_koszty?: string;
    zabezpieczenie?: boolean;
    district_court?: any;
    zal_wyd_wpl?: string;
    zal_wyd_roz?: string;
    zal_wyd_zwr?: string;
    zal_saldo?: string;
    statushistory?: any[];
    events?: any[];
    payment?: any[];
    cost?: any[];
    exequeries? : any;
    exeseizures? : any; 
    recomendations?: Recomendation[];
    exectitles?: Partial<Exectitle[]>;
    last_payment_request? : any;
}

export interface Exectitle {
    title_sign?: string;
    name?: string;
    title_date?: string | Date;
    clause_code?: string;
    clause_date?: string | Date;
}

export interface Recomendation {
    code: string;
    suggestion: string;
    start_date: string | Date;
}