import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import {Subject, Observable } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { NbAuthService, NbAuthJWTToken, NbAuthSimpleToken } from '@nebular/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from '../../../auth/services/current-user.service';
import { License } from '../../../@core/data/models/license';
import { LicensePopupComponent } from './license-popup/license-popup.component';
import { User } from '../../../@core/data/models/user';
import { Plugin } from '../../../@core/data/models/plugin';
import { SubjectService } from '../../../@core/data/services/subject/subject.service';
import { AccessCheckerService } from '../../../auth/services/access-checker.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;

  loggedIn: boolean;
  pluginObj: Partial<Plugin>;
  current_license: Partial<License>;
  license_active: boolean = false;
  licensePopup = LicensePopupComponent;

  current_user: Partial<User>;
  subject_uuid: string;
  smallScreen: boolean = false;

  currentTheme = 'dark';

  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private authService: NbAuthService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private subjectService: SubjectService,
    private route: ActivatedRoute,
    private accessChecker: AccessCheckerService
  ) {
    

    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));


      this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken ) => {
        if(token.getPayload()){
          this.loggedIn = true;
          this.currentUserService.getCurrentLicense().subscribe(
            (result => {
              this.current_license = result;
              this.license_active = this.checkLicense(this.current_license);
            })
          );
          this.currentUserService.getUser().subscribe(
            (result) => {
              this.current_user = result;
              this.current_user.fullName = this.getUserFullName(this.current_user);
            }
          )
          this.currentUserService.getSubject().subscribe(
            (result) => {
              this.subject_uuid = result.uuid;
            }
          )
          this.accessChecker.hasPerm(['view_own_key', 'change_own_key']).subscribe(
            (hasPerm: boolean) => {
              if (hasPerm == true){
                this.subjectService.getActiveSubjectPlugin(this.subject_uuid).subscribe(
                  (result) => this.pluginObj = result
                )
              }
            }
          )
        }
      });
  }

  checkLicense(license: Partial<License>) {
    let start_date = this.getDate(license.start_date as string);
    let end_date = this.getDate(license.end_date as string);
    let today = this.getDate();

    if(start_date <= today && today <= end_date) return true;
    else return false;
  }

  getUserFullName(user: Partial<User>) : string {
    if(user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`;
    else return user.email;
  }

  getDate(date_str?: string) {
    // Returns Date objects with time set to 0.
    // Allows date part comparison.

    let date: Date;

    if (date_str)
      date = new Date(date_str);

    else
      date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
  }


  openSettings() {
    this.router.navigate(['settings'], {relativeTo: this.route})
  }

  ngOnInit() {
    this.currentTheme = localStorage.getItem("SBStheme");

    //this.authService.isAuthenticated()
    //  .subscribe((isAuth: boolean)=> {
    //    this.loggedIn = isAuth;
    //  });

    const { md } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < md),
        takeUntil(this.destroy$),
      )
      .subscribe((isSmall: boolean) => this.smallScreen = isSmall);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.loggedIn = false;
    this.currentUserService.logout();
  }
}
