import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject,OnInit, Input } from '@angular/core';
import { NbLoginComponent, NbAuthResult, NbAuthJWTToken, NbTokenService, NB_AUTH_OPTIONS,NbAuthService , NbAuthSocialLink, getDeepFromObject  } from '@nebular/auth';
import { Router } from '@angular/router';
import {TwoFactoryAuthDialogComponent} from './app-login-tfa-window/app-login-tfa-window.component';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'ngx-app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLoginComponent implements OnInit {


  ngOnInit(): void {
    this.strategy = "email";
  }
  
  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;

  constructor(protected service: NbAuthService,
              private dialogService: NbDialogService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected router: Router,
              
              ) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
        if (result.getResponse().error.TFACodeUUID){
            this.user.TFACodeUUID = result.getResponse().error.TFACodeUUID;
            const window = this.openWindow();
            window.onClose.subscribe(
              (data)=>{
                this.user.TFACode = data;
                this.login();
              }
            );
        }
      }

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  openWindow() {
    return this.dialogService.open(TwoFactoryAuthDialogComponent, {closeOnBackdropClick:false, context: {"uuid" : this.user.TFACodeUUID, "title" : this.errors}});
  }

}

