import { RefreshTokenInterceptor } from './auth/interceptors/refresh-token-interceptor-jwt'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthOAuth2JWTToken, getDeepFromObject} from '@nebular/auth';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';

import { PermissionGuard } from './guards/permission.guard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { JWTInterceptor } from './auth/interceptors/jwt-interceptor';
import { environment } from '../environments/environment';
import '@angular/common/locales/global/pl';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthModule } from './auth/auth.module';




@NgModule({
  declarations: [AppComponent, RedirectComponent, NotFoundComponent],
  imports: [
    AuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbToastrModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name : 'email',
          token: {
            class: NbAuthOAuth2JWTToken,
            key: 'token'
          },
        
          baseEndpoint: environment.apiUrl,
          login: {
            redirect: {
              success: '/redirect',
              failure: null
            },
            endpoint: '/token/',
            method: 'post',
            requireValidToken: true,
            defaultErrors: ['Brak komunikacji z usługą BMS. Sprawdź czy jest dozwolona komunikacja na porcie 8000. Jeżeli problem dalej występuje skontaktuj sie z administaratorem.'],
          },
          refreshToken : {
            endpoint : '/refresh-token/',
            redirect : {
              success: '/',
              failure: 'auth/login/'
            },
            requireValidToken: true
          },
          logout: {
            endpoint: '',
          },
          requestPass: {
            endpoint: '/password_reset/',
            method: 'post',
            redirect: {
              success: '/auth/login',
              failure: null,
            },
            defaultErrors: ['Konto o podanym adresie e-mail nie istnieje lub hasło nie może zostać zmienione.'],
            defaultMessages: ['Informacje o dalszym postępowaniu zostały wysłane na wskazany adres e-mail'],
          },
          resetPass: {
            endpoint: '/password_reset/confirm/',
            method: 'post',
            redirect: {
              success: '/auth/login',
              failure: null,
            },
            resetPasswordTokenKey: 'token',
            defaultErrors: ['Coś poszło nie tak. Pamiętaj, że hasła nie mogą się powtarzać i hasło musi zawierać: jedną dużą litere, jedną małą, jedną cyfre, jeden znak z !@#$%^&*()<>,.'],
            defaultMessages: ['Twoje hasło zostało pomyślnie zmienione.'],
          },
          messages: {
            key: '',
            getter: (module, res, options) => getDeepFromObject(
              res.body,
              options.messages.key,
              options[module].defaultMessages,
            ),
          },
          errors: {
            key: 'message',
            getter: (module, res, options) => {
              if(res.error[options.errors.key])
                return res.error[options.errors.key];
              else
                return options[module].defaultErrors;
            }
          }
          
        }),
      ],
      forms: {
        login: {
          redirectDelay: 0,
          showMessages: {
            success: true,
            error: true
          }
        },
        validation : {
          email : {
            required : true,
            regexp: "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}"
          }
        },
        resetPassword: {
          strategy: "email"
        },
        requestPassword: {
          strategy: "email"
        },
        
      }, 
    }),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }
    )
  ],
  providers: [
      {
        provide : HTTP_INTERCEPTORS , useClass: RefreshTokenInterceptor , multi: true
      },
      {
        provide : HTTP_INTERCEPTORS , useClass: JWTInterceptor , multi: true
      },
      { 
        provide: LOCALE_ID, useValue: 'pl' 
      },
      PermissionGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
