import { Component, EventEmitter, Input, OnInit, Output,ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-app-login-tfa-window',
  templateUrl: './app-login-tfa-window.component.html',
  styleUrls: ['./app-login-tfa-window.component.scss']
})
export class TwoFactoryAuthDialogComponent implements OnInit {
  tfaCode: string = '';
  tfaCode2: string[] = ['', '', '', '', '', ''];
  @Input() uuid: string;
  @Input() title: string[];
  @ViewChild("customInput1") customInput1: ElementRef;
  @ViewChildren('customInput1, customInput2, customInput3, customInput4, customInput5, customInput6') 
  customInput: QueryList<ElementRef>;
  
  
  constructor(private dialogRef: NbDialogRef<any>) { }
  
  ngOnInit(): void { 
  }
  
  
  
  cancel() {
    this.dialogRef.close(this.tfaCode);
  }
  

  submit() {
    this.tfaCode = this.tfaCode2.join('');
    this.dialogRef.close(this.tfaCode);
  }

  lastKeyEventTimestamp: number = 0;

  onKeyPress(event: KeyboardEvent) {
    const regex = /^[0-9a-zA-Z]+$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }
  
   pasteFromClipboard(event: ClipboardEvent) {
    const clipboardContent = event.clipboardData?.getData('text') || '';
    const tfaCodeChars = clipboardContent.split('');
    for (let i = 1; i <= 6; i++) {
        const char = tfaCodeChars[i - 1] || '';
        this.tfaCode2[i - 1] = char;
    }
    
    if (this.customInput1) {
      this.customInput1.nativeElement.focus();
    }
  }

  moveFocus(event: KeyboardEvent, nextInput: number) {
    const isDigit = /^\d$/.test(event.key);
    const isLetter = /^[a-zA-Z]$/.test(event.key);
    
   
    const currentTimestamp = new Date().getTime();
    if (currentTimestamp - this.lastKeyEventTimestamp < 150) {
      return;
    }
    this.lastKeyEventTimestamp = currentTimestamp;
    
    if ((isDigit || isLetter) && nextInput <= 4) {
      const inputElements = this.customInput.toArray();
      inputElements[nextInput+1].nativeElement.focus();
      
  }
  else if (event.key === "Backspace" && nextInput > 0) {
      
      const inputElements = this.customInput.toArray();
      inputElements[nextInput - 1].nativeElement.focus();
  }
}

  
    /* USAGE HINT 
  
    confirmDeleteUser(user: User) {
      this.dialogService.open(ConfirmDialogComponent, 
        {
          context: 
            { 
              message: "Czy na pewno chcesz usunać użytkownika "+ user.username + "?",
              data: user,
              status: "danger"
            }
        })
        .onClose.subscribe(confirmed => {
          if(confirmed) this.deleteUser(user);
        });
      }
    */
    
  }