<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">BMS</a>
  </div>
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action 
      [nbPopover]="licensePopup" 
      nbPopoverTrigger="click"
      nbPopoverContext="current_license"  
      class="current-license" 
      *ngIf="current_license.end_date">
      <span *ngIf="!smallScreen" [ngClass]="license_active ? 'active' : 'inactive'">Licencja ważna do {{current_license.end_date | date: 'yyyy-MM-dd'}}</span>
      <nb-icon *ngIf="smallScreen" [status]="license_active ? 'basic' : 'danger'" icon="archive-outline"></nb-icon>
    </nb-action>
    <nb-action *ngIf="pluginObj">
      <span *ngIf="!smallScreen" [ngClass]="pluginObj.active ? 'pluginActive' : 'pluginInactive'" >Wtyczka </span>
      <nb-icon *ngIf="smallScreen" [status]="pluginObj.active ? 'success' : 'danger'" icon="activity-outline"></nb-icon>
      od {{pluginObj.modified_at | date: 'yyyy-MM-dd'}}
    </nb-action>
    <nb-action *ngIf="!smallScreen" class="control-item">
      {{current_user.fullName | uppercase}}
    </nb-action>
    <nb-action class="control-item" *requiredPerms="['can_search']">
      <nb-search
        type="rotate-layout"
        matRipple
        [matRippleUnbounded]="true"
        [matRippleCentered]="true"
      ></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="settings-2-outline" (click)="openSettings()"></nb-action> 
    <nb-action class="control-item" icon="power-outline" (click)="logout()" *ngIf="loggedIn">
      <button nbButton (click)="logout()"> icon="power-outline" </button>
    </nb-action> 
  </nb-actions>
</div>
