import { Component } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { NbMenuItem } from '@nebular/theme';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../auth/services/current-user.service';
import { Subject } from '../../@core/data/models/subject';

@Component({
  selector: 'ngx-redirect',
  styleUrls: ['redirect.component.scss'],
  template: ``,
})
export class RedirectComponent {

  subject : Partial<Subject>;
  constructor(private currentUserService: CurrentUserService, private router: Router, private authService: NbAuthService) {
    
    this.authService.isAuthenticated().subscribe(
      (isAuth: boolean) => {
        if (isAuth == true) {
          this.currentUserService.getSubject()
          .subscribe((subject: Partial<Subject>) => {
            this.subject = subject;
            if (this.subject) {
              if(this.subject.is_admin == true) this.router.navigate(['admin']);
              else if(this.subject.is_admin == false) this.router.navigate(['customer']);
              else 
              {
                this.currentUserService.logout();
              }
            }
            else {
              this.currentUserService.logout();
            }
          }); 
        }
        else {
          this.currentUserService.logout();
        }
      }
    )
  }
}
