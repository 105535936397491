import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbPopoverModule,
  NbBadgeModule,
  NbTagModule,
  NbInputModule,
  NbDatepickerModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { AuthModule } from '../auth/auth.module';
import { PermRequiredDirective } from './directives/perm-required.directive';
import { LicensePopupComponent } from './components/header/license-popup/license-popup.component';
import { SearchingTableComponent } from './components/searching-table/searching-table.component';
import { SearchingFiltersComponent } from './components/searching-table/searching-filters/searching-filters.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SearchingTableHeaderItemComponent } from './components/searching-table/searching-table-header-item/searching-table-header-item.component';
const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  AuthModule,
  NbPopoverModule,
  NbBadgeModule,
  NbTagModule,
  NbInputModule,
  FormsModule,
  ReactiveFormsModule,
  NbDatepickerModule
];
const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  SearchingTableComponent,
  LicensePopupComponent, 
  SearchingFiltersComponent,
  SearchingTableHeaderItemComponent
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe
];

const DIRECTIVES = [
  PermRequiredDirective
]

@NgModule({
  imports: [CommonModule, MatRippleModule, ...NB_MODULES],
  exports: [CommonModule, MatRippleModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {

  static forChild(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule
    }
  }

  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: ThemeProvider.getTheme(),
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }

  
}

export class ThemeProvider {
  static getTheme() {
    let theme = localStorage.getItem("SBStheme");
    if(!theme)
    {
      return 'dark';
    }
    else
      return theme;
  }
}
