import { Component, Input, OnInit } from '@angular/core';
import { License } from '../../../../@core/data/models/license';
import { CurrentUserService } from '../../../../auth/services/current-user.service';

@Component({
  selector: 'ngx-license-popup',
  templateUrl: './license-popup.component.html',
  styleUrls: ['./license-popup.component.scss']
})
export class LicensePopupComponent implements OnInit {

  current_license: Partial<License>;


  constructor(private currentUserService: CurrentUserService) { }

  ngOnInit(): void {
    this.currentUserService.getCurrentLicense().subscribe(
      ((result: Partial<License>) => {
        this.current_license = result;
      })
    )
  }
}
