<div *requiredPerms="['view_license']" class="license-details" style="padding: 1rem;">
    <p><b>Numer licencji: </b> {{current_license.license_number}}</p>
    <p><b>Typ: </b> <span *ngIf="current_license.license_type; else noData">{{current_license.license_type}}</span></p>
    <p><b>Data rozpoczęcia obowiązywania: </b> {{current_license.start_date | date: 'yyyy-MM-dd'}}</p>
    <p><b>Data zakończenia obowiązywania: </b> {{current_license.end_date | date: 'yyyy-MM-dd'}}</p>
    <p><b>Data podpisania umowy: </b> {{current_license.contract_date | date: 'yyyy-MM-dd'}}</p>
    <ng-template #noData>
      Brak
    </ng-template>    
</div>
