import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Group } from '../../models/group';
import { User } from '../../models/user';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  api_url = environment.apiUrl;
  cached_user: Observable<any>;

  //private user_list_request: Observable<User[]>;
  //private user_request: Observable<User>;


  constructor(private http: HttpClient) { }

  clearCache() {
    this.cached_user = null;
  }

  getUserList(params?: any) {
    return this.http.get(this.api_url + '/users', {params: params}).pipe(
      //shareReplay()
    );
  }
  

  getUser(uuid: string, params?: any) {
    if(!this.cached_user)
    {
      this.cached_user = this.http.get(this.api_url + '/users/' + uuid).pipe(
        map((response: any) => response["data"]),
        shareReplay()
      )
    }
    return this.cached_user;
  }

  postUser(user: any, params?: any): Observable<User> {
    return this.http.post(this.api_url + '/users/', user).pipe(
      map((response: any) => response["data"])
    );
  }

  deleteUser(user: any): Observable<any> {
    return this.http.delete(this.api_url + '/users/' + user.uuid)
  }

  getGroups(uuid: string, option: string): Observable<Group[]> {
    return this.http.get(this.api_url+'/users/'+uuid+'/groups/', {params: {option: option}}).pipe(
      map((response: any) => 
      response["data"].map((item: any) => item
      ))
    );
  }

  patchUser(user: any): Observable<User> {
    return this.http.patch(this.api_url + '/users/' + user.uuid + '/', user).pipe(
      map((response: any) => response["data"])
    );
  }

  setUserGroups(groups: Group[], uuid?: string): Observable<Group[]> {
    return this.http.patch(this.api_url+'/users/'+uuid+'/groups/', groups).pipe(
      map((response: any) =>
      response["data"].map((item: any) => item
      ))
    );
  }

  getUserReportPermGroups(user_uuid: string) :Observable<any> {
    return this.http.get(`${this.api_url}/users/${user_uuid}/report-groups`);
  }

  setUserReportPermGroups(groups: Partial<Group>[], user_uuid: string) :Observable<any> {
    return this.http.patch(`${this.api_url}/users/${user_uuid}/report-groups/`, groups);
  }

  changePassword(user: any, params: any): Observable<any> {
    let url = this.api_url+'/users/change-password/';
    return this.http.post(url, params)
  }

  public getImpersonateToken(user_uuid: string): Observable<any> {
    return this.http.post(`${this.api_url}/users/${user_uuid}/impersonate`, {});
  }
}
