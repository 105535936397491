<ng-container *ngIf="sort ; else base;">
    <div class="sortable" (click)="sortBy(field)">
        <ng-content></ng-content>
        <nb-icon 
            [icon]="sortObj.sortOrder?'arrow-ios-downward-outline':'arrow-ios-upward-outline'"
            [hidden] ="sortObj.sortField!=field"
        >
        </nb-icon>
    </div>

</ng-container>
<ng-template #base>
    {{title}}
</ng-template>

