import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanLoad, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject } from '../@core/data/models/subject';
import { CurrentUserService } from '../auth/services/current-user.service';
import { Location } from '@angular/common';
import { Route } from '@angular/compiler/esm2020/src/core.mjs';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate, CanActivateChild, CanLoad {

  fail_message: string = "Nie posiadasz uprawnień do tego adresu."
  constructor(
    private location: Location,
    private currentUserService: CurrentUserService ){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUserService.getSubject().pipe(
      map((subject: Partial<Subject>) => {
        if(subject.is_admin === true) return true;
        else  
        {
          this.location.back();
          window.alert(this.fail_message);
          return false;
        }
      })
    )
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.canActivate(next, state);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
      return this.currentUserService.getSubject().pipe(
        map((subject: Partial<Subject>) => {
          if(subject.is_admin === true) return true;
          else  
          {
            this.location.back();
            window.alert(this.fail_message);
            return false;
          }
        })
      )
  }
}
