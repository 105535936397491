import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NbAuthModule } from '@nebular/auth';
import { 
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbCardModule
} from '@nebular/theme';
import { TwoFactoryAuthDialogComponent } from './app-login/app-login-tfa-window/app-login-tfa-window.component';
import { AppLoginComponent } from './app-login/app-login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RequestPasswordComponent } from './request-password/request-password.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
    NbCardModule
  ],
  declarations: [
    // ... here goes our new components
    TwoFactoryAuthDialogComponent,
    AppLoginComponent,
    ResetPasswordComponent,
    RequestPasswordComponent],
  providers :
  [
    
  ]
  
})

export class AuthModule { }
