import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count } from 'console';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { defaultIfEmpty, isEmpty, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Case, Recomendation } from '../../models/case';

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  api_url = environment.apiUrl;
  query: string;
  cashed_case: Observable<any>;
  
  constructor(private http: HttpClient) { 

  }

  clearCache() {
    this.query = null;
    this.cashed_case = null;
  }
  
  getCasesList(params?: any) {
    return this.http.get(this.api_url + "/cases", {params: params});
  }

  getCase(uuid: string) {
    if(!this.cashed_case)
    {
      this.cashed_case = this.http.get(this.api_url+"/cases/"+uuid).pipe(
        shareReplay()
      )
    }
    return this.cashed_case; 
  }

  getPossibleStatusList() {
    return this.http.get<any[]>(this.api_url+"/cases/statuses");
  }

  getExportResultFile(url: string): Observable<Blob> {
    return this.http.get(url, {responseType: 'blob'});
  }

  getRecomendations(uuid: string): Observable<Recomendation[]> {
    let url = `${this.api_url}/recomendations/`
    return this.http.get<Recomendation[]>(url, {params: {uuid: uuid}});
  }

  public deleteCase(uuid: string): Observable<any> {
    return this.http.delete(`${this.api_url}/cases/${uuid}`);
  }

}
