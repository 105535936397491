<nb-card size="large" style="width: 40%; min-width: 360px;margin: 0 auto;">
  <nb-card-header>{{title}}</nb-card-header>
  <nb-card-body>
    
      <p>Na państwa skrzynkę pocztową został wysłany kod weryfikacji dwuetapowej, proszę uzupełnić podany kod w polach poniżej.</p>
      <div style="display: flex; justify-content: center; width: 100%;" (paste)="pasteFromClipboard($event)">
      <input #customInput1  nbInput maxlength="1"  [(ngModel)]="tfaCode2[0]" class="custom-input" (keyup)="moveFocus($event, 0)" (keypress)="onKeyPress($event)">
      <span class="hyphen">-</span>
      <input #customInput2  nbInput maxlength="1" [(ngModel)]="tfaCode2[1]" class="custom-input" (keyup)="moveFocus($event, 1)" (keypress)="onKeyPress($event)">
      <span class="hyphen">-</span>
      <input #customInput3  nbInput maxlength="1" [(ngModel)]="tfaCode2[2]" class="custom-input" (keyup)="moveFocus($event, 2)" (keypress)="onKeyPress($event)">
      <span class="hyphen">-</span>
      <input #customInput4  nbInput maxlength="1"  [(ngModel)]="tfaCode2[3]" class="custom-input" (keyup)="moveFocus($event, 3)" (keypress)="onKeyPress($event)">
      <span class="hyphen">-</span>
      <input #customInput5  nbInput maxlength="1"  [(ngModel)]="tfaCode2[4]" class="custom-input" (keyup)="moveFocus($event, 4)" (keypress)="onKeyPress($event)">
      <span class="hyphen">-</span>
      <input #customInput6  nbInput maxlength="1" [(ngModel)]="tfaCode2[5]" class="custom-input" (keyup)="moveFocus($event, 5)" (keypress)="onKeyPress($event)">
     </div>
    
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <button nbButton status="success" (click)="submit()">Prześlij</button>
      <p style="color: gray">{{uuid}}</p>
    </div>
  </nb-card-footer>
</nb-card>
