<bms-searching-filters [tableConfig]="tableConfig" (searchEvent)="reloadData($event)">
</bms-searching-filters>
<table #searchTable class="header">
    <thead>
        <tr>
            <ng-content select="[header]"></ng-content>
        </tr>
    </thead>
</table>
<div  [ngClass]="{'body' :true, 'without-paginator' : !showPaginator}" (scroll)="tableScroled(searchTable)">
    <table>
        <tbody >
            <ng-container *ngIf="items?.length; else emptymessageTemplate">
                <tr (click)="rowClicked(item)" *ngFor="let item of items">
                    <ng-template *ngTemplateOutlet="bodyTemplate; context{item: item,config:config}"></ng-template>
                </tr>
            </ng-container>

        </tbody>
    </table>
</div>
<div class="tfooter" [hidden]="!items || !showPaginator">
    <div class="left">
        <ng-content select="[paginatorleft]"></ng-content>
    </div>
    <div class="paginator">
        Wyniki od {{pageSize*(actualPageNumber-1)}} do {{pageSize*(actualPageNumber-1) + items?.length}} z {{totalRecords}} wszystkich
        <div class="PagesPaginatorGroup">
            <button 
                nbButton 
                ghost
                [disabled] = "actualPageNumber==1"
                (click)="reloadData({})"
            >
                <nb-icon icon="arrowhead-left-outline"></nb-icon>
            </button>
            <button 
                nbButton 
                ghost
                [disabled] = "actualPageNumber==1"
                (click)="reloadData({'page':actualPageNumber-1})"
            >
                <nb-icon icon="arrow-ios-back-outline"></nb-icon>
            </button>
            <div class="PagesPaginatorGroupNumbers">
                <button 
                    nbButton 
                    ghost
                    *ngFor="let pagenumber of pages" 
                    (click)="reloadData({'page':pagenumber})"
                    [status]="pagenumber==actualPageNumber ? 'primary' : 'basic'"
                >
                {{pagenumber}}
            </button>
            </div>
            <button 
                nbButton 
                ghost
                [disabled] = "actualPageNumber==lastPage()"
                (click)="reloadData({'page':actualPageNumber+1})"
            >   
                <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
            </button>
            <button 
                nbButton 
                ghost
                [disabled] = "actualPageNumber==lastPage()"
                (click)="reloadData({'page':lastPage()})"
            >
                <nb-icon icon="arrowhead-right-outline"></nb-icon>
            </button>
        </div>
        <nb-select [(selected)]="pageSize" (selectedChange)="reloadData({'page':1,'page_size':pageSize})">
            <nb-option [value]="option" *ngFor="let option of rowsPerPageOptions">{{option}}</nb-option>
        </nb-select>
    </div>
    <div class="right">
        <ng-content select="[paginatorright]"></ng-content>
    </div>
</div>