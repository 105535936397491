<h1 id="title" class="title">Zaloguj się</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Błąd!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Udało się!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Adres E-mail:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.email"
           #email="ngModel"
           name="email"
           id="input-email"
           placeholder="Adres E-mail"
           autofocus
           type="email"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [pattern]="getConfigValue('forms.validation.email.regexp')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Pole jest wymagane!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Nieprawidłowy adres e-mail!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Hasło:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           placeholder="Hasło"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Hasło jest wymagane!
      </p>
      <p class="caption status-danger" *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
        Hasło powinno zawierać
        od {{ getConfigValue('forms.validation.password.minLength') }}
        do {{ getConfigValue('forms.validation.password.maxLength') }}
        znaków
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <a class="forgot-password" routerLink="../request-password">Zapomniałeś hasła?</a>
  </div>

  <button nbButton
          fullWidth
          status="success"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted"
          >
    Zaloguj
  </button>
</form>
